import React from 'react';
import InstaCard from '../../../components/instaCard/InstaCard';
import EventPhoto1 from '../../../Assets/Images/event-photo-1.png';
import EventPhoto2 from '../../../Assets/Images/event-photo-2.png';
import './Events.scss';

const Events = () => {
  return (
    <div className='homepage-events' >
      <div className='homepage-events-left' >
        <span className='homepage-events-heading' >ACARA & KEGIATAN</span>

        <span className='homepage-events-main' >Tetap bersama kami!</span>

          <iframe src="https://cdn.lightwidget.com/widgets/b819845288d75043b158597ec80f4fbe.html" scrolling="no"
                  allowTransparency="true" className="lightwidget-widget"
                  style={{ width: '100%', border: 0, overflow: 'hidden' }}></iframe>

      </div>

    </div>
  );
};

export default Events;