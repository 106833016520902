import React, { useContext, useState, useEffect } from "react";
import Layout from "../components/Layout";
import { LangStateContext } from "../context/LangContext";
import '../styles/HomePage.scss';
import '../index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { IntlProvider, FormattedMessage } from 'react-intl';

import NarativeApps from "../sections/homePage/narativeApps/NarativeApps";
import WhyChoose from "../sections/homePage/whyChoose/WhyChoose";
import Features from "../sections/homePage/features/Features";
import Testimonials from "../sections/homePage/testimonials/Testimonials";
import CheckPremium from "../sections/homePage/checkPremium/CheckPremium";
import Events from '../sections/homePage/events/Events';
import { bahasaMessages } from '../oldPages/homePage/HomePageMessages';

// Images Import
import GooglePlayDownload from "../Assets/Images/google-play-download.png";
import Stars from "../Assets/Images/stars.png";
import StarsShort from "../Assets/Images/stars-short.png";
import DarkCloud from "../Assets/Images/dark-cloud.png";
import HomepageDownloadCloud from "../Assets/Images/homepage-download-cloud.png";
import Alby from "../Assets/Images/alby.png";
import VirtualGlass from "../Assets/Images/virtual-glass.png";
// import AngpaoPintar from "../Assets/Images/ANGPAO-PINTAR.png";
import DarkCloudLeftShort from "../Assets/Images/dark-cloud-left-short.png";
import DarkCloudRightShort from "../Assets/Images/dark-cloud-right-short.png";

import '../styles/HomePage.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SEO from '../components/seo/Seo';

const Index = () => {

  const { isIndoSelected } = useContext(LangStateContext);
  console.log(isIndoSelected);
  console.log("test");
  // States
  const [bgImg, setBgImg] = useState(Stars);
  // const [modal, setModal] = useState();
  // const [modalHasShown, setModalHasShown] = useState(true);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (modalHasShown) {
  //       setModal(true);
  //     }
  //   }, 3000);

  //   return () => {
  //     clearInterval(timer);
  //   }
  // }, [modalHasShown]);

  // Render Functions
  // const renderEventPopup = () => {
  //   return (
  //     <div className="modal" style={ modal ? {display: 'block'} : {display: 'none'} }> 
  //       <div className="modal-content" onClick={() => {
  //         setModal(false)
  //         setModalHasShown(false)
  //       }}>
  //         <Link 
  //           to="/angpaopintarchallenge" 
  //           target="_blank" 
  //           rel="noopener noreferrer" 
  //           onClick={() => { 
  //             setModal(false)
  //             setModalHasShown(false)
  //           }}
  //         >
  //           <img src={AngpaoPintar} alt="Angpao Pintar"/>
  //         </Link>
  //       </div>
  //     </div>
  //   );
  // };

  const renderHeader = () => {

    let mql;  
    if (typeof window !== 'undefined') mql = window.matchMedia("(max-width: 900px)"); 

    if (mql !== undefined) {
      mql.addEventListener("change", (e) => {
        const mobileView = e.matches;
        if (mobileView) {
        setBgImg(StarsShort);
      } else {
        setBgImg(Stars);
      }
    });
  }
    
    return (
      <IntlProvider
        messages={isIndoSelected ? bahasaMessages : bahasaMessages}
        locale='en'
        defaultLocale='id'
      >
        <div className="homepage-header">

          <img src={bgImg} alt="" className="homepage-header-stars" />

          <h1 className="homepage-header-heading">
            <FormattedMessage id='heading' >
            </FormattedMessage>
          </h1>
          <p className="homepage-header-sub">
            <FormattedMessage id='subHeading' />
          </p>

          <div className="homepage-header-download">
            {/*<a target="_blank" href="https://apps.apple.com/nl/app/titik-pintar/id1485849836">*/}
            {/*  <img*/}
            {/*    src={AppStoreDownload}*/}
            {/*    className="homepage-header-download-app-store "*/}
            {/*    alt=""*/}
            {/*  />*/}
            {/*</a>*/}
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tp.apk&referrer=utm_source%3Dwebsite%26utm_medium%3Dhomepage">
              <img
                src={GooglePlayDownload}
                alt=""
                className="homepage-header-download-google-play "
              />
            </a>

          </div>

          <img
            src={HomepageDownloadCloud}
            className="homepage-header-light-cloud-left"
            alt=""
          />

          <img
            src={HomepageDownloadCloud}
            className="homepage-header-light-cloud-right"
            alt=""
          />

          <img
            src={DarkCloud}
            className="homepage-header-dark-cloud-left"
            alt=""
          />

          <img
            src={DarkCloudLeftShort}
            alt=""
            className="homepage-header-dark-cloud-left short"
          />

          <img
            src={DarkCloud}
            className="homepage-header-dark-cloud-right"
            alt=""
          />

          <img
            src={DarkCloudRightShort}
            className="homepage-header-dark-cloud-right short"
            alt=""
          />

          <img src={Alby} alt="" className="homepage-header-img-alby" />
          <img src={VirtualGlass} alt="" className="homepage-header-img-glass" />

          <div className="homepage-header-bottom-cut left" />
          <div className="homepage-header-bottom-cut right" />



        </div>
      </IntlProvider>
    );
  };

  return (
    <Layout>
      <SEO 
        title='Aplikasi belajar terbaik untuk anak sekolah dasar - Titik Pintar'
        description='Aplikasi belajar dengan kurikulum sekolah dasar yang digabungkan dengan game dan animasi sehingga belajar menjadi lebih menarik. '
        keywords='Titik Pintar, Anak, Game, Edukasi, Anak SD, Game Edukasi, Titik Pintar Game Edukasi, Kurikulum SD, Aplikasi belajar terbaik, sekolah dasar'
      />
      <div className="homepage">
        {/* {renderEventPopup()} */}
        {renderHeader()}
        <NarativeApps isIndoSelected={isIndoSelected} />
        <WhyChoose isIndoSelected={isIndoSelected} />
        <Features isIndoSelected={isIndoSelected} />
        <Testimonials isIndoSelected={isIndoSelected} />
        <Events/>
        {/* <CheckPremium isIndoSelected={isIndoSelected} /> */}
      </div>
    </Layout>
    
  );
};

export default Index;
