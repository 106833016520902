export const englishMessages = {
  main: 'Make Your Kids’ Screen Time Worthy!',
  pointOneHeading: 'High-quality Subjects',
  pointOneText: 'High-quality lessons created by experienced teachers from local and international schools',
  pointTwoHeading: 'Kids’ Favorite',
  pointTwoText: 'Cute and colorful illustrations.',
  pointThreeHeading: 'Affordable',
  pointThreeText: 'High-quality game with low subscription fee compare to the most education program',
  pointFourHeading: 'Outstanding Features!',
  pointFourText: 'Customizable avatar, Coin Pintar, minigames, new lessons every week!',
  heading: 'WHY CHOOSE US',
};

export const bahasaMessages = {
  main: 'Buat Waktu Screen Time Berkualitas!',
  pointOneHeading: 'Pelajaran Berkualitas Tinggi',
  pointOneText: 'Pelajaran dibuat oleh guru profesional yang berpengalaman di sekolah nasional maupun internasional.',
  pointTwoHeading: 'Favorit anak',
  pointTwoText: 'Dipenuhi Ilustrasi lucu dan warna-warni.',
  pointThreeHeading: 'Terjangkau',
  pointThreeText: 'Harga langganan lebih murah dibanding program edukasi lainnya.',
  pointFourHeading: 'Fitur-fitur Luar biasa!',
  pointFourText: 'Dari avatar menarik, koin pintar, mini games hingga pelajaran baru setiap minggunya!',
  heading: 'KENAPA MEMILIH KAMI?',
};