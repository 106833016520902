import React from "react";
import PropTypes from "prop-types";
import "./Testimonial.scss";

const Testimonial = ({ text, img, name, designation }) => {
  return (
    <div className="testimonial">
      <span className="testimonial-quote">"</span>
      <span className="testimonial-text">{text}</span>
      <div className="testimonial-credentials">
        <img
          className="testimonial-img"
          src={img}
          alt={name}
        />
        <div>
          <span className="testimonial-credentials-name">{name}</span>
          <span className="testimonial-credentials-designation">
            {designation}
          </span>
        </div>
      </div>
    </div>
  );
};

Testimonial.propTypes = {
  text: PropTypes.any,
  img: PropTypes.any,
  name: PropTypes.string,
  designation: PropTypes.string,
};

export default Testimonial;
