export const englishMessages = {
  heading: 'TESTIMONIALS',
  mainBlack: 'Parents & Teachers',
  mainAlmond: 'Love Us',
  testimonial1Text: 'My child is now more enthusiastic to study because he can play while learning and also hone his creativity',
  testimonial2Text: 'The animations are really interesting, colorful, and available in Bahasa Indonesia or English. The lessons are great according to the elementary school curriculum. Plus there is a brief discussion, so our children know why the answer is right or wrong.',
  testimonial3Text: 'As a mother, I have no worry if my child spend her free time with Titik Pintar',
  testimonial4Text: 'I am very happy that there is an educational app such as TITIK PINTAR. My children are now more knowledgeable, even though sometimes when they don’t understand, they ask mom and dad, and there are games too ... how cool!',
  testimonial5Text: 'The first time I tried Titik Pintar, wowww, my daughter Myisha was immediately enthusiastic. What a coincidence there is a ChaCha character who is also the nickname of my daughter, Myisha that makes her even more excited.',
};

export const bahasaMessages = {
  heading: 'TESTIMONI',
  mainBlack: 'Para Orang Tua & Guru',
  mainAlmond: 'Menyukai Kami',
  testimonial1Text: 'Anak saya sekarang jadi lebih antusias karena bisa main game sambil belajar, yg tentunya mampu mengasah kecerdasan dan kreativitasnya.',
  testimonial2Text: 'Animasinya menarik bangett, colourfull, dan tersedia dlm bahasa Indonesia atau English.. Materinya berbobot sesuai kurikulum Sekolah Dasar. Plus ada pembahasan singkatnya, jadi anak kita tau kenapa jawabannya bisa benar atau salah.',
  testimonial3Text: 'Sebagai ibu, aku lebih tenang bila si kecil menghabiskan waktu luangnya bersama @titikpintar',
  testimonial4Text: 'Saya senang sekali ada aplikasi edukatif seperti TITIK PINTAR. Abang dan adik jadi bertambah pengetahuannya, meskipun sesekali yang tidak bisa bertanya pada ayah dan ibu, dan ada games nya juga...keren bgt😍',
  testimonial5Text: 'Pertama kali nyoba aplikasi @titikpintar ini wowww putriku Myisha langsung antusias. Kebetulan sekali ada tokoh ChaCha yg persis dengan nama panggilan myisha bikin dia tambah semangat.',
};