import React, { useEffect } from "react";
import { IntlProvider, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Testimonial from "../../../components/testimonial/Testimonial";
import Slider from "react-slick";
import { englishMessages, bahasaMessages } from './TestimonialsMessages';

// Testimonial Images
import Testimonial1Img from '../../../Assets/Images/testimonial-1.jpg';
import Testimonial2Img from '../../../Assets/Images/testimonial-2.jpg';
import Testimonial3Img from '../../../Assets/Images/testimonial-3.jpg';
import Testimonial4Img from '../../../Assets/Images/testimonial-4.jpg';
import Testimonial5Img from '../../../Assets/Images/testimonial-5.jpg';

import "./Testimonials.scss";

const Testimonials = ({ isIndoSelected }) => {

  const settings_3 = {
    dots: true,
    autoplay: false,
    slidesToShow: 3,
    className:'testimonials-list',

    responsive: [{
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      }
    }]
  }

  useEffect(() => {
    const currentSlides = document.querySelectorAll('.slick-slide.slick-active');
  }, []);

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      <div className="homepage-testimonials">
        <span className="homepage-testimonials-heading">
          <FormattedMessage id='heading' />
        </span>
        <span className="homepage-testimonials-main">
          <FormattedMessage id='mainBlack' />{' '}
          <span className="almond-text homepage-testimonials-main-love-us">
            <FormattedMessage id='mainAlmond' />
          </span>
        </span>

        {/* <div className="homepage-testimonials-carousel"> */}
        <Slider {...settings_3} >
          <Testimonial
            text={<FormattedMessage id='testimonial1Text' />}
            name="Sulastri Zaenudin"
            designation="@sulastrizaenudin"
            img={Testimonial1Img}
          />
          <Testimonial
            text={<FormattedMessage id='testimonial2Text' />}
            name="Reny Oktarika"
            designation="@reny.oktarika"
            img={Testimonial2Img}
          />
          <Testimonial
            text={<FormattedMessage id='testimonial3Text' />}
            name="Tiffany"
            designation="@_me_tiff"
            img={Testimonial3Img}
          />
          <Testimonial
            text={<FormattedMessage id='testimonial4Text' />}
            name='Yulia'
            designation="@yuliazwa_2807"
            img={Testimonial4Img}
          />
          <Testimonial
            text={<FormattedMessage id='testimonial5Text' />}
            name='Jauza Mazaya Andayu'
            designation="@mazaya2401"
            img={Testimonial5Img}
          />
        </Slider>
        {/* </div> */}
      </div>
    </IntlProvider>
  );
};

Testimonials.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Testimonials;
