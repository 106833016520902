export const englishMessages = {
  main: 'Irresistible Educational Game for Elementary kids',
  para: 'Titik Pintar is more than just a game. Your kids will enjoy learning while playing interactive games. The lessons are easier to understand with interesting illustrations.',
  pointOneHeading: 'No 1 in Indonesia',
  pointOneText: 'The #1 EdTech with the Indonesian curriculum for elementary age kids.',
  pointTwoHeading: 'Interactive Education',
  pointTwoText: 'The lessons are created with interactive games and colorful illustration for kids.',
  pointThreeHeading: 'Best Screen Time',
  pointThreeText: 'Provide high-quality screen time for kids. Mom and dad will be worry-free! ',
  heading: 'WHAT IS TITIK PINTAR?',
};

export const bahasaMessages = {
  main: 'Game Edukasi yang Tidak Membosankan Untuk Anak',
  para: 'Titik Pintar lebih dari sekedar game. Si kecil kini bisa bermain sambil belajar. Gali mata pelajaran lebih seru dan mudah dipahami dengan ilustrasi dan animasi!',
  pointOneHeading: 'No. 1 di Indonesia',
  pointOneText: 'Game nomer 1 dengan kurikulum nasional untuk anak SD.',
  pointTwoHeading: 'Edukasi Interaktif!',
  pointTwoText: 'Pelajaran dikemas dengan game interaktif & ilustrasi penuh warna!',
  pointThreeHeading: 'Screentime Bermutu',
  pointThreeText: 'Screentime​ lebih berkualitas untuk si kecil. Ibu & Ayah jadi lebih tenang!',
  heading: 'APA ITU TITIK PINTAR?'
};