import React from "react";
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import Button from "../../../components/button/Button";
import ShrubsLeft from "../../../Assets/Images/shrubs-left.png";
import ShrubsRight from "../../../Assets/Images/shrubs-right.png";
import CustomAvatar from "../../../Assets/Images/custom-avatar.png";
import NewLesson from "../../../Assets/Images/new-lesson.png";
import CoinCollection from "../../../Assets/Images/coin-collection.png";
import MiniGames from "../../../Assets/Images/mini-games.png";
import { englishMessages, bahasaMessages } from './FeaturesMessages';
import { navigate } from "gatsby"

import "./Features.scss";

const settings = {
  dots: true,
  autoplay: false,
  slidesToShow: 1,
}
const Features = ({ isIndoSelected }) => {

  const History = useHistory();

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      {/* Desktop Component */}
      <div className="homepage-features">
        <div className="homepage-features-left">
          <div className="homepage-features-feature">
            <div className="homepage-features-feature-blue">
              <img
                src={NewLesson}
                alt="Pelajaran"
                className="homepage-features-feature-img new-lesson"
              />
            </div>
            <span className="homepage-features-feature-heading">
              <FormattedMessage id='newLessonHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='newLessonText' />
            </span>
          </div>

          <div className="homepage-features-feature">
            <div className="homepage-features-feature-blue">
              <img
                src={MiniGames}
                alt="Minigames"
                className="homepage-features-feature-img mini-games"
              />
            </div>
            <span className="homepage-features-feature-heading">
              <FormattedMessage id='miniGamesHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='miniGamesText' />
            </span>
          </div>

          <div className="homepage-features-feature margin-top">
            <div className="homepage-features-feature-blue">
              <img
                src={CoinCollection}
                alt="Koin"
                className="homepage-features-feature-img coin-collection"
              />
            </div>
            <span className="homepage-features-feature-heading">
              <FormattedMessage id='coinCollectionHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='coinCollectionText' />
            </span>
          </div>

          <div className="homepage-features-feature margin-top">
            <img
              src={CustomAvatar}
              alt="Buat avatar"
              className="homepage-features-feature-img custom-avatar"
            />
            <span className="homepage-features-feature-heading">
              <FormattedMessage id='avatarHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='avatarText' />
            </span>
          </div>
        </div>

        <div className="homepage-features-right">
          <span className="homepage-features-right-heading">
            <FormattedMessage id='rightHeading' />
          </span>
          <span className="homepage-features-right-main">
            <FormattedMessage id='rightMain' />
          </span>
          <div className="homepage-features-right-btn">
            <Button type="white" onClick={() => navigate("/fitur")} >
              <FormattedMessage id='rightCta' />
            </Button>
          </div>
        </div>
        <img
            src={ShrubsLeft}
            alt="Tanaman"
            className="homepage-features-shrubs-left"
        />
        <img
          src={ShrubsRight}
          alt="Tanaman"
          className="homepage-features-shrubs-right"
        />
      </div>

      {/* Mobile Component */}
      <div className="homepage-features-mobile">
        <div className="homepage-features-mobile-relative">
          <div className="homepage-features-mobile-header">
            <span className="homepage-features-mobile-header-heading">
              <FormattedMessage id='rightHeading' />
            </span>
            <span className="homepage-features-mobile-header-main">
              <FormattedMessage id='rightMain' />
            </span>{" "}
          </div>

          <img
            src={ShrubsRight}
            alt="Tanaman"
            className="homepage-features-mobile-shrubs"
          />
        </div>

        <Slider { ...settings } className="homepage-features-mobile-features">
          <div className="homepage-features-mobile-feature">
            <div className="homepage-features-feature-blue">
              <img
                src={NewLesson}
                alt="Pelajaran"
                className="homepage-features-feature-img new-lesson"
              />
            </div>
            <span className="homepage-features-mobile-feature-heading">
              <FormattedMessage id='newLessonHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='newLessonText' />
            </span>
          </div>

          <div className="homepage-features-mobile-feature">
            <div className="homepage-features-feature-blue">
              <img
                src={MiniGames}
                alt="Minigames"
                className="homepage-features-feature-img mini-games"
              />
            </div>
            <span className="homepage-features-mobile-feature-heading">
              <FormattedMessage id='miniGamesHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='miniGamesText' />
            </span>
          </div>

          <div className="homepage-features-mobile-feature">
            <div className="homepage-features-feature-blue">
              <img
                src={CoinCollection}
                alt="Koin"
                className="homepage-features-feature-img coin-collection"
              />
            </div>
            <span className="homepage-features-mobile-feature-heading">
              <FormattedMessage id='coinCollectionHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='coinCollectionText' />
            </span>
          </div>

          <div className="homepage-features-mobile-feature">
            <img
              src={CustomAvatar}
              alt="Buat avatar"
              className="homepage-features-feature-img custom-avatar"
            />
            <span className="homepage-features-mobile-feature-heading">
              <FormattedMessage id='avatarHeading' />
            </span>
            <span className="homepage-features-feature-text">
              <FormattedMessage id='avatarText' />
            </span>
          </div>
        </Slider>

        <div className="homepage-features-mobile-btn">
          <Button type="blue" block onClick={() => navigate("/fitur")} >
            <FormattedMessage id='rightCta' />
          </Button>
        </div>
      </div>
    </IntlProvider>
  );
};

Features.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Features;
