export const englishMessages = {
  newLessonHeading: 'New Lesson Every Week',
  newLessonText: 'Lots of exciting lessons are waiting for you!',
  miniGamesHeading: 'Mini Games',
  miniGamesText: 'Play fun games after completing the lessons',
  coinCollectionHeading: 'Coin Pintar Collection',
  coinCollectionText: 'Use coins to play mini games and buy an outfit.',
  avatarHeading: 'Customizable Avatar',
  avatarText: 'Change the outfit and colours of your avatar!',
  rightHeading: 'GAMES FEATURES',
  rightMain: 'KIDS will have a good time!',
  rightCta: 'See More Features',
};

export const bahasaMessages = {
  newLessonHeading: 'Live Class setiap Senin-Jumat',
  newLessonText: 'Pelajaran yang seru dan Guru yang keren menunggumu',
  miniGamesHeading: 'Mini Games',
  miniGamesText: 'Mainkan games seru setelah menyelesaikan pelajaran',
  coinCollectionHeading: 'Kumpulkan Koin Pintar',
  coinCollectionText: 'Gunakan Koin Pintar untuk bermain minigames dan membeli pakaian avatarmu.',
  avatarHeading: 'Avatar yang Dapat Disesuaikan',
  avatarText: 'Ganti pakaian dan warna avatarmu sesuka hati!',
  rightHeading: 'Fitur Game',
  rightMain: 'Banyak fitur yang disukai anak-anak!',
  rightCta: 'Cek fitur lengkap',
};