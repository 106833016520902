import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player'
import Slider from "react-slick";
import AlbyClown from "../../../Assets/Images/alby-clown.png";
import PhoneApp from "../../../Assets/Images/phone-app.png";
import NumberOne from "../../../Assets/Images/number-one.png";
import AlbySailor from "../../../Assets/Images/alby-sailor.png";
import Like from "../../../Assets/Images/like.png";
import Love from "../../../Assets/Images/love.png";
import PlayerBg from '../../../Assets/Images/player-bg.jpg';
import { bahasaMessages, englishMessages } from './NarativeAppsMessages';
import "./NarativeApps.scss";

const settings = {
  dots: true,
  autoplay: false,
  slidesToShow: 3,
  arrows: false,

  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 10000,
      settings: "unslick",
    },
  ],
};

const NarativeApps = ({ isIndoSelected }) => {

  //const narativePlayerRef = useRef(null);

  //const [videoUrl, setVideoUrl] = useState('https://storage.googleapis.com/titikpintar/20201221%20-%20Titik%20Pintar%20Website%20Video%20Bahasa.mp4');

  // useEffect(() => {
  //   if (isIndoSelected) return setVideoUrl('https://storage.googleapis.com/titikpintar/(Bahasa)%20Titik%20Pintar%20new%20website%20video.mp4');
  //   else return setVideoUrl('https://storage.googleapis.com/titikpintar/(English)%20Titik%20Pintar%20new%20website%20video.mp4');
  // }, [isIndoSelected]);

  // useEffect(() => {
  //   narativePlayerRef.current.load();
  // }, [videoUrl]);

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      <div className="homepage-narative-apps">
        <div className="homepage-narative-apps-row">
          <div>
            <span className="homepage-narative-apps-heading">
              <FormattedMessage id='heading' />
            </span>
            <span className="homepage-narative-apps-main">
              <FormattedMessage id='main' />
              {/* <span className="almond-text">Game Edukasi </span>
              yang Tidak{" "}
              <span className="tablet-line-break">Membosankan Untuk Anak</span> */}
            </span>
            <p className="homepage-narative-apps-para">
              <FormattedMessage id='para' />
              {/* <span className="tablet-line-break">
                Titik Pintar lebih dari sekedar game. Si kecil kini bisa
              </span>{" "}
              <span className="tablet-line-break">
                bermain sambil belajar. Gali matapelajaran lebih seru
              </span>
              dan mudah dipahami dengan ilustrasi dan animasi. */}
            </p>
          </div>

          <div className="homepage-narative-apps-video-container">
            <img
              src={Like}
              alt="Suka"
              className="homepage-narative-apps-video-container-like"
            />
            <img
              src={Love}
              alt="Cinta"
              className="homepage-narative-apps-video-container-love one"
            />
            <img
              src={Love}
              alt="Cinta"
              className="homepage-narative-apps-video-container-love two"
            />
            <img
              src={Love}
              alt="Cinta"
              className="homepage-narative-apps-video-container-love three"
            />
            <ReactPlayer url='https://prod-s3-direct-upload-bucket-sahabatpintar.s3.ap-southeast-1.amazonaws.com/Titik-Pintar-Website-Video.5af9c942.mp4' 
            controls={true} width='100%' height='100%'
            light={PlayerBg}
            playing
            />
            <img
              src={AlbySailor}
              alt="Pelaut alby"
              className="homepage-narative-apps-alby-sailor"
            />
          </div>
        </div>

        <Slider {...settings} className="homepage-narative-apps-points">
          <div className="homepage-narative-apps-point">
            <span className="homepage-narative-apps-point-heading">
              <FormattedMessage id='pointOneHeading' />
            </span>
            <img
              src={NumberOne}
              alt="Nomor satu"
              className="homepage-narative-apps-point-img"
            />
            <span className="homepage-narative-apps-point-text">
              <FormattedMessage id='pointOneText' />
            </span>
          </div>

          <div className="homepage-narative-apps-point two">
            <span className="homepage-narative-apps-point-heading">
              <FormattedMessage id='pointTwoHeading' />
            </span>
            <img
              src={AlbyClown}
              alt="Badut alby"
              className="homepage-narative-apps-point-img"
            />
            <span className="homepage-narative-apps-point-text">
              <FormattedMessage id='pointTwoText' />
            </span>
          </div>

          <div className="homepage-narative-apps-point three">
            <span className="homepage-narative-apps-point-heading">
              <FormattedMessage id='pointThreeHeading' />
            </span>
            <div className="homepage-narative-apps-point-blue">
              <img
                src={PhoneApp}
                alt="Aplikasi di handphone"
                className="homepage-narative-apps-point-img phone-app"
              />
            </div>
            <span className="homepage-narative-apps-point-text">
              <FormattedMessage id='pointThreeText' />
            </span>
          </div>
        </Slider>
      </div>
    </IntlProvider>
  );
};

NarativeApps.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default NarativeApps;
