import React from "react";
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import MultiLang from "../../../Assets/Images/multi-lang.png";
import MultiLangShort from "../../../Assets/Images/multi-lang-short.png";
import Boy from "../../../Assets/Images/boy.png";
import Girl from "../../../Assets/Images/girl.png";
import IndoFlag from "../../../Assets/Images/indo-flag.png";
import UKFlag from "../../../Assets/Images/uk-flag.png";
import AlbyFoxSmall from "../../../Assets/Images/alby-fox-small.png";
import AlbyFox from "../../../Assets/Images/alby-fox.png";
import { englishMessages, bahasaMessages } from './WhyChooseMessages';
import "./WhyChoose.scss";

const WhyChoose = ({ isIndoSelected }) => {
  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className="homepage-choose">
        <span className="homepage-choose-heading">
          <FormattedMessage id='heading' />
        </span>
        <span className="homepage-choose-main">
          <FormattedMessage id='main' />
        {/* <span className='tablet-line-break' >Buat Waktu Screen Time</span> <span className='almond-text' >Berkualitas!</span> */}
        </span>

        <div className="homepage-choose-boxes">
          <div className="homepage-choose-box-lang-desktop">
            <img
              src={MultiLang}
              alt="Multi bahasa"
              className="homepage-choose-box-lang-desktop-img"
            />
          </div>

          {/* Desktop version lesson quality */}
          <div className="homepage-choose-box-lesson-quality-desktop">
            <span className="homepage-choose-box-lesson-quality-desktop-heading">
              <FormattedMessage id='pointOneHeading' />
              {/* Pelajaran <br /> Berkualitas Tinggi */}
            </span>
            <p className="homepage-choose-box-lesson-quality-desktop-text">
              <FormattedMessage id='pointOneText' />
              {/* Pelajaran dibuat oleh guru profesional yang berpengalaman di sekolah
              nasional maupun internasional. */}
            </p>
          </div>

          {/* Mobile version lesson quality */}
          <div className="homepage-choose-box-lesson-quality-mobile">
            <span className="homepage-choose-box-lesson-quality-mobile-heading">
              <FormattedMessage id='pointOneHeading' />
              {/* Pelajaran Berkualitas Tinggi */}
            </span>
            <p className="homepage-choose-box-lesson-quality-mobile-text">
              <FormattedMessage id='pointOneText' />
              {/* Pelajaran dibuat oleh guru profesional yang berpengalaman di sekolah
              nasional maupun internasional. */}
            </p>
            <img
              src={UKFlag}
              alt="Bendera inggris"
              className="homepage-choose-box-lesson-quality-mobile-uk"
            />
            <img
              src={IndoFlag}
              alt="Bendera indonesia"
              className="homepage-choose-box-lesson-quality-mobile-indo"
            />
            <img
              src={MultiLangShort}
              alt="Multi bahasa"
              className="homepage-choose-box-lesson-quality-mobile-img"
            />
          </div>

          {/* Desktop version fav child */}
          <div className="homepage-choose-box-fav-child-desktop-container" >
              <img
                src={AlbyFox}
                alt="Rubah alby"
                className="homepage-choose-box-fav-child-desktop-fox"
              />
            <div className="homepage-choose-box-fav-child-desktop">
              <div>
                <span className="homepage-choose-box-fav-child-desktop-heading">
                  <FormattedMessage id='pointTwoHeading' />
                </span>
                <span className="homepage-choose-box-fav-child-desktop-text">
                  <FormattedMessage id='pointTwoText' />
                  {/* Dipenuhi Ilustrasi lucu <br/> dan warna-warni. */}
                </span>
              </div>
            </div>
          </div>

          {/* Mobile version fav child */}
          <div className="homepage-choose-box-fav-child-mobile">
            <div>
              <span className="homepage-choose-box-fav-child-mobile-heading">
                <FormattedMessage id='pointTwoHeading' />
              </span>
              <span className="homepage-choose-box-fav-child-mobile-text">
                <FormattedMessage id='pointTwoText' />
              </span>
            </div>
          </div>
        </div>

        <div className="homepage-choose-boxes">
          {/* Desktop version affordable */}
          <div className="homepage-choose-box-desktop-affordable">
            <span className="homepage-choose-box-desktop-affordable-percent">
              %
            </span>
            <span className="homepage-choose-box-desktop-affordable-heading">
              <FormattedMessage id='pointThreeHeading' />
            </span>
            <span className="homepage-choose-box-desktop-affordable-text">
              <FormattedMessage id='pointThreeText' />
            </span>
          </div>

          {/* Mobile version affordable */}
          <div className="homepage-choose-box-mobile-affordable">
            <span className="homepage-choose-box-mobile-affordable-percent">
              %
            </span>
            <span className="homepage-choose-box-mobile-affordable-heading">
              <FormattedMessage id='pointThreeHeading' />
            </span>
            <span className="homepage-choose-box-mobile-affordable-text">
              <FormattedMessage id='pointThreeText' />
            </span>
          </div>

          <div className="homepage-choose-box-amazing-features">
            <span className="homepage-choose-box-amazing-features-heading">
              <FormattedMessage id='pointFourHeading' />
            </span>
            <span className="homepage-choose-box-amazing-features-text">
              <FormattedMessage id='pointFourText' />
            </span>

            <img
              src={AlbyFoxSmall}
              alt="Rubah alby"
              className="homepage-choose-box-amazing-features-img-alby-fox"
            />
          </div>

          <div className="homepage-choose-box-kids">
            <img src={Boy} alt="Anak laki-laki" className="homepage-choose-box-kids-boy" />
            <img src={Girl} alt="Anak perempuan" className="homepage-choose-box-kids-girl" />
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};

WhyChoose.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default WhyChoose;
